<template>
  <div id="app">
    <nav>
      <img src="https://img.hbcygc.net/website/pc/images/logo.png" @click="btn_link('home')">
      <router-link to="/Through">穿越号</router-link>
      <router-link to="/Screen">巨幕号</router-link>
      <router-link to="/Future">未来号</router-link>
      <a href="javascript:;" @mouseenter="clickFosMenu">Future OS</a>
      <div class="nav-login" @click="btn_link('My')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" class="bi bi-person">
          <path
            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z">
          </path>
        </svg>
      </div>
      <div class="menu-fos" v-if="isFosMenu" @mouseleave="closeFosMenu">
        <router-link to="/Fos">FOS简介</router-link>
        <router-link to="/Ar">Future AR</router-link>
        <router-link to="/Sdk">Future 开发者联盟</router-link>
      </div>
    </nav>
    <router-view />
    <footer>
      <div class="footer-menu">
        <div class="footer-menu-child">
          <a href="javascript:;" class="footer-title">只见未来</a>
          <router-link to="/Through">穿越号</router-link>
          <router-link to="/Screen">巨幕号</router-link>
          <router-link to="/Future">未来号</router-link>
          <router-link to="/5g">3D双摄手机</router-link>
        </div>
        <div class="footer-menu-child">
          <a href="javascript:;" class="footer-title">未来全屏通</a>
          <router-link to="/Fos">FOS简介</router-link>
          <router-link to="/Ar">Future AR</router-link>
          <router-link to="/Sdk">Future 开发者联盟</router-link>
        </div>
        <div class="footer-menu-child">
          <a href="javascript:;" class="footer-title">关于我们</a>
          <router-link to="/Meta">未来元宇宙</router-link>
          <router-link to="/Company">公司简介</router-link>
          <router-link to="/About">联系我们</router-link>
        </div>
      </div>
      <div class="btn btn-outline-light text-dark"
        style="margin: 3rem 0 2rem 0;border-radius: 2rem;padding: 0.3rem 2rem;">
        <router-link class="f-zx" to="/Consult">立即咨询</router-link>
      </div>
      <div class="footer-line">
        <img class="img-fluid" src="https://img.hbcygc.net/website/pc/images/thread.png">
      </div>
      <div class="footer-end">
        <p>武汉东湖新技术开发区雄庄路8号曙光星城D区2号楼1001室</p>
        <p>Copyright 2020-2023 Future Fullscreen Intelligent Technology Group Co., Ltd.鄂ICP备2023002274号-1</p>
        <p class="ba">
          <img src="./assets/police-badge.png" />
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=42018502007119" rel="noreferrer"
            target="_blank">鄂公网安备42018502007119</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<style lang="less">
@font-face {
  font-family: "dyzg";
  src: url('./font-familys/douyuzhuiguang.ttf')
}

@font-face {
  font-family: "sy";
  src: url('./font-familys/SourceHanSansCN-Heavy.ttf')
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  z-index: -10;
  height: auto;
  margin: auto;
}

body {
  position: relative;
  z-index: -100;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  width: 0;
}

.trunk {
  position: relative;
  z-index: -5;
  width: 100%;
  min-height: 100vh;
}

.floor {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: -1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  .f-nothing {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .floor-product {
    margin-top: 5%;
    width: 45%;
    height: auto;
  }

  .font-absolute {
    position: absolute;
    z-index: 1;
    width: 100%;

    .name {
      font-size: 3rem;
    }

    .title {
      font-size: 3.5rem;
    }
  }

  .btns {
    position: absolute;
    bottom: 10%;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;

    a {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      text-decoration: none;
      color: white;
      border-radius: 2rem;
      display: block;
      width: 10rem;
      height: 2.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      font-weight: bold;
      border: 2px solid white;
    }

    .btn-through:hover {
      border: 0;
    }

    a:visited {
      color: white;
    }

    a:hover {
      color: black;
      background-color: white;
    }

    a:active {
      color: white;
    }
  }
}

.floor-change {
  .throught-type {
    .circle {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      cursor: pointer;
    }

    .colour1 {
      background-color: #5ECEEA;
    }

    .colour2 {
      background-color: #FAACBE;
    }

    .ring {
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }

    .ring1-avtive {
      border: 2px solid #5ECEEA;
    }

    .ring2-avtive {
      border: 2px solid #FAACBE;
    }
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 3s;
  }

  .fade-enter-to,
  .fade-leave {
    opacity: 1;
  }
}

.bottom-shadow {
  background-image: url('https://img.hbcygc.net/website/pc/index/video-buttom.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0.9;
}

/* 包裹箭头效果的盒子 */
.arrowSS {
  animation: bounce-inSS 2s infinite;
  position: relative;
  /* 启动动画特效 */
}

// /* 箭头效果的盒子 */
// .arrowSS_style {
//   cursor: pointer;
// }
/* 箭头动画 */
@keyframes bounce-inSS {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-3vh);
  }

  60% {
    transform: translateY(-1vh);
  }
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0.5rem 0;

  img {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    cursor: pointer;
  }

  a {
    color: #fff;
    text-decoration: none;
    padding: 6px 25px 6px 25px;
    border-radius: 22px;
    cursor: pointer;
    transition: 0.5s;
    background-color: rgba(100, 100, 100, 0.4);

    &.router-link-exact-active {
      color: #b3e5fc;
    }
  }

  .nav-login {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    color: white;
    transition: 0.5s;
    cursor: pointer;
    background-color: rgba(100, 100, 100, 0.4);
    padding: 0.5rem;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-fos {
    position: fixed;
    top: 3rem;
    right: 3.5rem;
    border-radius: 1rem;
    background-color: rgba(100, 100, 100, 0.4);
    padding: 0.5rem 0;

    a {
      width: 100%;
      display: block;
      background-color: rgba(0, 0, 0, 0);
      font-size: 0.7rem;
    }
  }
}

footer {
  background-image: url('https://img.hbcygc.net/website/pc/images/bottombg.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 800;
  width: 100%;
  height: auto;
  color: #9E9E9E;
  padding: 4rem 0 2rem 0;

  a {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    display: block;
  }

  .f-zx:hover {
    color: black;
    background-color: white;
  }


  .footer-line {
    width: 100%;
    height: auto;
  }

  .footer-menu {
    display: flex;
    justify-content: center;
  }

  .footer-menu-child {
    width: 20vw;
    height: auto;

    a {
      margin: 0.5rem 0;
    }

    &.router-link-exact-active {
      color: #b3e5fc;
    }
  }

  .footer-title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .footer-end {
    padding-top: 2rem;
    font-family: Avenir, Helvetica, Arial, sans-serif;

    p,
    a {
      font-size: 0.8rem;
      color: #9E9E9E;
    }

    .ba {
      display: flex;
      justify-content: center;
      column-gap: 0.5rem;

      img {
        width: auto;
        height: 1rem;
      }
    }
  }
}

.modal-back {
  background-color: rgba(100, 100, 100, 0.5);
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
</style>
<script>
export default {
  data() {
    return {
      isFosMenu: false
    }
  },
  created() {
    this.windowsChange();
  },
  mounted() {
    window.onresize = () => {
      this.windowsChange();
    }
  },
  methods: {
    btn_link: function (page) {
      this.$router.push({ name: page }).catch(ca => ca);
    },
    clickFosMenu: function () {
      this.isFosMenu = !this.isFosMenu;
    },
    closeFosMenu: function () {
      this.isFosMenu = false;
    },
    windowsChange: function () {
      var cw = document.body.clientWidth;
      if (cw < 850) {
        window.location.href = 'https://m.future.top'
      }
    }
  }
}
</script>
