var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trunk v-home"},[_c('div',{staticClass:"floor"},[_c('video',{attrs:{"muted":"muted","autoplay":"autoplay","loop":"loop"},domProps:{"muted":true}},[_c('source',{attrs:{"src":"https://img.hbcygc.net/website/pc/index/topvideo.mp4","type":"video/mp4"}})]),_c('div',{staticClass:"bottom-shadow"}),_vm._m(0)]),_c('div',{staticClass:"floor floor-change"},[_c('div',{staticClass:"btns"},[_c('router-link',{staticClass:"btn-blank btn-through",attrs:{"to":"/Appointment?productId=cdfc1061-0ccd-11ee-b565-00163e2ab162"}},[_vm._v("立即预约")]),_c('router-link',{attrs:{"to":"/Through"}},[_vm._v("了解更多")])],1),(_vm.showThroughBack == true)?_c('div',{staticClass:"f-nothing",staticStyle:{"background-image":"url('https://img.hbcygc.net/website/pc/index/floor2.png')"}},[_c('img',{staticClass:"floor-product",attrs:{"src":"https://img.hbcygc.net/website/t1/homes/p-product2-2.png"}}),_vm._m(1)]):_vm._e(),(_vm.showThroughBack == false)?_c('div',{staticClass:"f-nothing"},[_vm._m(2)]):_vm._e()]),_c('div',{staticClass:"floor",staticStyle:{"background-image":"url('https://img.hbcygc.net/website/pc/index/floor3.png')"}},[_c('img',{staticClass:"floor-product",attrs:{"src":"https://img.hbcygc.net/website/pc/index/product3.png"}}),_vm._m(3),_c('div',{staticClass:"btns"},[_c('router-link',{attrs:{"to":"/Screen"}},[_vm._v("了解更多")])],1)]),_c('div',{staticClass:"floor",staticStyle:{"background-image":"url('https://img.hbcygc.net/website/pc/index/floor4.png')"}},[_c('img',{staticClass:"floor-product",attrs:{"src":"https://img.hbcygc.net/website/pc/index/product4.png"}}),_vm._m(4),_c('div',{staticClass:"btns"},[_c('router-link',{attrs:{"to":"/Future"}},[_vm._v("了解更多")])],1)]),_c('div',{staticClass:"floor",staticStyle:{"background-color":"black"}},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"btns"},[_c('router-link',{attrs:{"to":"/Fos"}},[_vm._v("了解更多")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-head animate__animated"},[_c('p',{staticStyle:{"font-size":"6rem","font-family":"'dyzg'"}},[_vm._v("触发想象，启动未来")]),_c('p',{staticStyle:{"font-size":"2rem"}},[_vm._v("次视代智能眼镜，以精彩画质与精密制程，打造智能眼镜高标准！")]),_c('div',{staticClass:"arrowSS"},[_c('img',{staticClass:"to-mouse",attrs:{"src":"https://img.hbcygc.net/website/pc/images/mouse.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-absolute animate__animated",staticStyle:{"color":"#4c4c4c"}},[_c('p',{staticClass:"name"},[_vm._v("M.vision"),_c('span',{staticStyle:{"color":"#0396BC"}},[_vm._v("穿越号")])]),_c('p',{staticClass:"title"},[_vm._v("轻装出行时尚，精彩跃然镜上")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-nothing"},[_c('div',{staticClass:"d-img",staticStyle:{"z-index":"0","background-image":"url('http://img.hbcygc.net/website/pc/index/back-201-1-1.png')"}}),_c('div',{staticClass:"d-img",staticStyle:{"z-index":"10","background-image":"url('https://img.hbcygc.net/website/t1/homes/p1.png')"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-absolute animate__animated"},[_c('p',{staticClass:"name"},[_vm._v("M.vision"),_c('span',{staticStyle:{"color":"#E3FE58"}},[_vm._v("巨幕号")])]),_c('p',{staticClass:"title"},[_vm._v("畅享超大巨幕，体验全面震撼")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-absolute animate__animated"},[_c('p',{staticClass:"name"},[_vm._v("M.vision"),_c('span',{staticStyle:{"color":"#2D4EC8"}},[_vm._v("未来号")])]),_c('p',{staticClass:"title"},[_vm._v("率先走近虚实，轻松触达未来")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","height":"100%","position":"absolute","left":"0","top":"0","display":"flex","align-items":"center","justify-content":"center"}},[_c('img',{staticStyle:{"width":"auto","height":"80%"},attrs:{"src":"https://img.hbcygc.net/website/pc/index/product5.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-absolute animate__animated"},[_c('p',{staticClass:"name"},[_vm._v("Future OS")]),_c('p',{staticStyle:{"font-size":"2rem"}},[_vm._v("完整的操作系统，纵享多重交互形态兼容现有技术 对接简单快捷")])])
}]

export { render, staticRenderFns }