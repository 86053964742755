import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Future',
    name: 'Future',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Future.vue')
  },
  {
    path: '/Screen',
    name: 'Screen',
    component: () => import('../views/Screen.vue')
  },
  {
    path: '/Through',
    name: 'Through',
    component: () => import('../views/Through.vue')
  }
  ,
  {
    path: '/Fos',
    name: 'Fos',
    component: () => import('../views/Fos.vue')
  }
  ,
  {
    path: '/Ar',
    name: 'Ar',
    component: () => import('../views/Ar.vue')
  }
  ,
  {
    path: '/Sdk',
    name: 'Sdk',
    component: () => import('../views/Sdk.vue')
  }
  ,
  {
    path: '/5g',
    name: '5g',
    component: () => import('../views/5g.vue')
  }
  ,
  {
    path: '/About',
    name: 'About',
    component: () => import('../views/About.vue')
  }
  ,
  {
    path: '/Company',
    name: 'Company',
    component: () => import('../views/Company.vue')
  }
  ,
  {
    path: '/Consult',
    name: 'Consult',
    component: () => import('../views/Consult.vue')
  }
  ,
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
  ,
  {
    path: '/Meta',
    name: 'Meta',
    component: () => import('../views/Meta.vue')
  }
  ,
  {
    path: '/My',
    name: 'My',
    component: () => import('../views/My.vue')
  }
  ,
  {
    path: '/Os',
    name: 'Os',
    component: () => import('../views/Os.vue')
  }
  ,
  {
    path: '/Appointment',
    name: 'Appointment',
    component: () => import('../views/Appointment.vue')
  }
  ,
  {
    path: '/Service',
    name: 'Service',
    component: () => import('../views/Service.vue')
  }
  ,
  {
    path: '/Uploads',
    name: 'Uploads',
    component: () => import('../views/Uploads.vue')
  }
  ,
  {
    path: '/Apps',
    name: 'Apps',
    component: () => import('../views/Apps.vue')
  }
  ,
  {
    path: '/Videos',
    name: 'Videos',
    component: () => import('../views/Videos.vue')
  }
  ,
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  }
  ,
  {
    path: '/Agreement',
    name: 'Agreement',
    component: () => import('../views/Agreement.vue')
  }
]

const router = new VueRouter({
  routes
})

// 跳转后返回顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

export default router
