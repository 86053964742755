<template>
    <div class="trunk v-home">
        <div class="floor">
            <video muted="muted" autoplay="autoplay" loop="loop">
                <source src="https://img.hbcygc.net/website/pc/index/topvideo.mp4" type="video/mp4">
            </video>
            <div class="bottom-shadow"></div>
            <div class="font-head animate__animated">
                <p style="font-size: 6rem;font-family: 'dyzg';">触发想象，启动未来</p>
                <p style="font-size: 2rem;">次视代智能眼镜，以精彩画质与精密制程，打造智能眼镜高标准！</p>
                <div class="arrowSS">
                    <img src="https://img.hbcygc.net/website/pc/images/mouse.png" class="to-mouse">
                </div>
            </div>
        </div>
        <div class="floor floor-change">
            <div class="btns">
                <router-link to="/Appointment?productId=cdfc1061-0ccd-11ee-b565-00163e2ab162" class="btn-blank btn-through">立即预约</router-link>
                <router-link to="/Through">了解更多</router-link>
            </div>
            <div class="f-nothing" v-if="showThroughBack == true"
                style="background-image: url('https://img.hbcygc.net/website/pc/index/floor2.png');">
                <img class="floor-product" src="https://img.hbcygc.net/website/t1/homes/p-product2-2.png">
                <div class="font-absolute animate__animated" style="color: #4c4c4c;">
                    <p class="name">M.vision<span style="color: #0396BC;">穿越号</span></p>
                    <p class="title">轻装出行时尚，精彩跃然镜上</p>
                </div>
            </div>
            <div class="f-nothing" v-if="showThroughBack == false">
                <div class="f-nothing">
                        <div class="d-img"
                            style="z-index: 0; background-image: url('http://img.hbcygc.net/website/pc/index/back-201-1-1.png')">
                        </div>
                        <div class="d-img"
                            style="z-index: 10; background-image: url('https://img.hbcygc.net/website/t1/homes/p1.png')">
                        </div>
                    </div>
            </div>
        </div>
        <div class="floor" style="background-image: url('https://img.hbcygc.net/website/pc/index/floor3.png');">
            <img class="floor-product" src="https://img.hbcygc.net/website/pc/index/product3.png">
            <div class="font-absolute animate__animated">
                <p class="name">M.vision<span style="color: #E3FE58;">巨幕号</span></p>
                <p class="title">畅享超大巨幕，体验全面震撼</p>
            </div>
            <div class="btns">
                <router-link to="/Screen">了解更多</router-link>
            </div>
        </div>
        <div class="floor" style="background-image: url('https://img.hbcygc.net/website/pc/index/floor4.png');">
            <img class="floor-product" src="https://img.hbcygc.net/website/pc/index/product4.png">
            <div class="font-absolute animate__animated">
                <p class="name">M.vision<span style="color: #2D4EC8;">未来号</span></p>
                <p class="title">率先走近虚实，轻松触达未来</p>
            </div>
            <div class="btns">
                <router-link to="/Future">了解更多</router-link>
            </div>
        </div>
        <div class="floor" style="background-color: black;">
            <div
                style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;display: flex;align-items: center;justify-content: center;">
                <img style="width: auto;height: 80%;" src="https://img.hbcygc.net/website/pc/index/product5.png">
            </div>
            <div class="font-absolute animate__animated">
                <p class="name">Future OS</p>
                <p style="font-size:2rem;">完整的操作系统，纵享多重交互形态兼容现有技术 对接简单快捷</p>
            </div>
            <div class="btns">
                <router-link to="/Fos">了解更多</router-link>
            </div>
        </div>
    </div>
</template>
<style scoped lang="less">
.floor {
    color: white;
    font-family: 'sy';

    video {
        width: 100%;
        position: relative;
        z-index: 0;
    }

    .bottom-shadow {
        bottom: -1px;
    }

    .font-head {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
        width: 100%;
        height: 20rem;
    }

    .f-nothing {
        .d-img {
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .floor-product {
        margin-top: 5%;
        width: 45%;
        height: auto;
    }

    .font-absolute {
        bottom: 20%;
    }

    .btns {
        .btn-through {
            background-color: #0396BC;
            border: 2px solid #0396BC;
        }
    }
}
.floor-change{
    .throught-type {
        width: 6rem;
        height: 3rem;
        position: absolute;
        z-index: 10;
        left: 8%;
        bottom: 28%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

</style>
<script>
export default {
    data() {
        return {
            showProductType: 'blue',
            showThroughBack: true
        }
    },
    mounted() {
        window.addEventListener("load", (e) => {
            $('.font-head').eq(0).addClass('animate__bounceInUp');
        });
        window.addEventListener("scroll", (e) => { this.ergodic(); });
    },
    methods: {
        ergodic: function () {
            if($('.v-home').length < 1) return;
            let _self = this;
            var screenHeight = window.screen.height;
            let hVal = screenHeight / 3;
            $.each($('.v-home').children('.floor'), function (index, item) {
                if (index == 0) return true;
                if (index == 1) {
                    if (item.getBoundingClientRect().top < (screenHeight / 3)) {
                        $(item).find('.font-absolute').addClass('animate__bounceInUp');
                    }
                    if (item.getBoundingClientRect().top < -50) {
                        _self.showThroughBack = false;
                    }
                } else {
                    if (item.getBoundingClientRect().top < hVal) {
                        $(item).find('.font-absolute').addClass('animate__bounceInUp');
                    }
                }

            });
        },
        showProduct: function (type) {
            this.showProductType = type;
        }
    }
}
</script>